import axios from 'axios'
import { Toast } from 'vant'
import store from '../store'
import router from '../router'
import { HttpStatusCode } from '@/utils/constant'

export function getHttpDomain() {
  const httpDomain = window.location.protocol + '//' + window.location.host
  return process.env.VUE_APP_BASE_API.indexOf('http') === 0 ? process.env.VUE_APP_BASE_API : (httpDomain + process.env.VUE_APP_BASE_API)
}

export function getWsDomain() {
  const wsDomain = (window.location.protocol === 'http:' ? 'ws:' : 'wss:') + '//' + window.location.host
  return process.env.VUE_APP_BASE_WS_API.indexOf('ws') === 0 ? process.env.VUE_APP_BASE_WS_API : (wsDomain + process.env.VUE_APP_BASE_WS_API)
}

const instance = axios.create({
  baseURL: getHttpDomain(),
  timeout: 60000
})

function showTip(message) {
  if (message) {
    Toast.fail(message)
  }
}

const WHITE_LIST = ['/user/wx_code_login', '/user/login_by_captcha', '/user/login_by_password', '/user/refresh-token', '/user/reg']

instance.interceptors.request.use(
  (config) => {
    // 白名单中接口及获取手机号验证码接口，不能带token字段，会被后端拦截器拦截，无限循环5002或者判断已经登录，状态异常
    if (store.getters.token && (!WHITE_LIST.includes(config.url) && !config.url.includes('/sms/send_captcha'))) {
      config.headers.token = store.getters.token
    }
    return config
  },
  (error) =>
    Promise.reject(error)
)

// 是否正在刷新的标记
let isRefreshing = false
//重试队列
let requests = []
instance.interceptors.response.use(
  (response) => {
    const res = response.data

    // 文件流直接返回
    if (response.config.headers && response.config.headers.responseType === 'blob') {
      return res
    }
    const { code } = res
    // console.log(res,'res')
    if (code !== HttpStatusCode.SUCCESS && code !== 0) {
      if (!(code === 111101 || code === 111108 || code === 111109 || code === 111110 || code === 111111 || code === HttpStatusCode.TOKEN_EXPIRED)) {
        const message = res.msg || res.message || '请求错误'
        showTip(message)
      }
      // token失效或者未登录，重新登录
      if (code === HttpStatusCode.TOKEN_EXPIRED) {
        // store.dispatch('user/refreshToken', { token: store.getters.refreshToken })
        //   .then(() => {
        //     return instance.request(response.request)
        //   })
        if (!isRefreshing) {
          isRefreshing = true
          //调用刷新token的接口
          return store.dispatch('user/refreshToken', { token: store.getters.refreshToken }).then(res => {
            const { token } = res.data
            // 替换token
            response.headers.Authorization = `${token}`
             // token 刷新后将数组的方法重新执行
            requests.forEach((cb) => cb(token))
            requests = [] // 重新请求完清空
            return instance(response.config)
          }).catch(err => {
          //跳到登录页
            router.push('/login')
            return Promise.reject(err)
          }).finally(() => {
            isRefreshing = false
          })
        } else {
          // 返回未执行 resolve 的 Promise
          return new Promise(resolve => {
            // 用函数形式将 resolve 存入，等待刷新后再执行
            requests.push(token => {
              response.headers.Authorization = `${token}`
              resolve(instance(response.config))
            })
          })
        }
      } else if (code === HttpStatusCode.UNAUTHORIZED || code == HttpStatusCode.UNAUTHLOGINOUT
        || code == HttpStatusCode.REFRESHTOKEN_EXPIRED || code == 401) {
        store.commit("user/setName", null)
        store.commit("user/setPhoneNum", null)
        store.commit("user/setToken", null)
        store.commit("user/setRefreshToken", null)
        store.commit("user/setWxCode", null)
        router.push('/login')
      } else if (code == HttpStatusCode.WXCODE_EXPRIRED) {
        // console.log('准备跳转',router)
        router.back()
      }
      return Promise.reject(res)
    }

    return res
  },
  (error) => {
    let { message } = error
    const { data } = error.response
    console.dir(data,'错误吗')
    message = data.message || data.msg
    showTip(message)
    return Promise.reject(error)
  }
)

export class Http {
  METHOD_GET = 'GET'
  METHOD_POST = 'POST'

  request(url, data = {}, options = {}) {
    return new Promise((resolve, reject) => {
      this._request(url, resolve, reject, data, options)
    })
  }

  post(url, data, options = {}) {
    options.method = this.METHOD_POST
    if (data && options.isFormData) {
      const formData = new FormData()
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key])
        }
      }
      data = formData
    }
    return this.request(url, data, options)
  }

  get(url, data, options = {}) {
    options.method = this.METHOD_GET
    return this.request(url, data, options)
  }

  _request(url, resolve, reject, data, options = {}) {
    const method = options.method || this.METHOD_GET
    const config = {}
    // 根据请求方法为对应字段赋值
    if (method === this.METHOD_GET) {
      config.params = data
    } else {
      config.data = data
    }

    if (options.headers) {
      config.headers = { ...config.headers, ...options.headers }
    }

    instance.request({
      url,
      method,
      ...config
    })
      .then(res => {
        if (res) {
          resolve(res)
        } else {
          showTip('服务器异常')
        }
      })
      .catch(err => {
        reject(err)
      })
  }
}
